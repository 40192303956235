import React from "react"
import SlideProjectorSSR from "../../../../shared/src/components/slide-projector/slide-projector-ssr"
import styled from "styled-components"

const SocialImageWrapper = styled.div`
  width: 1200px;
  height: 630px;
`

const SocialImage = ({ pageContext = {} }) => {
  const { title, titleVideoPoster } = pageContext
  console.log("RENDERING SOCIAL IMG", title, titleVideoPoster)
  const slideContent = { image: titleVideoPoster, title }
  return (
    <SocialImageWrapper>
      <SlideProjectorSSR slide={slideContent} noSound />
    </SocialImageWrapper>
  )
}

export default SocialImage
