import React, { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faTelegramPlane,
} from "@fortawesome/free-brands-svg-icons"
import { faRss } from "@fortawesome/free-solid-svg-icons"
import { LangContext } from "gatsby-source-dek-wp"
import "./footer.scss"

const LOGO_DEFAULT_WIDTH = 100

const translations = {
  projectPartners: {
    de: "Kooperationspartner und Förderer",
    en: "Cooperation Partners and Sponsors",
    ru: "Партнеры и спонсоры",
    ua: "Cooperation Partners and Sponsors",
  },
  mediaPartners: {
    de: "Medienpartner",
    en: "Media Partners",
    ru: "Медиа-партнеры",
    ua: "Media Partners",
  },
}

const iconMenuItems = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/dekoder.org",
    icon: faFacebookF,
  },
  { name: "Twitter", url: "https://twitter.com/dekoder_org", icon: faTwitter },
  { name: "RSS", url: "https://www.dekoder.org/rss_de_all.xml", icon: faRss },
  { name: "Telegram", url: "https://t.me/dekoder_org", icon: faTelegramPlane },
]

const Footer = ({ metaData }) => {
  const { projectPartners = [], mediaPartners = [] } = metaData
  const lang = useContext(LangContext)
  const year = new Date().getFullYear()

  const hasLogoFooter = !![...projectPartners, ...mediaPartners].length

  return (
    <div className="logo-footer">
      <footer className="ph-footer">
        © {year} dekoder gGmbH –{" "}
        <a
          href="https://www.dekoder.org/kontakt"
          rel="noopener noreferrer"
          target="_blank"
        >
          Kontakt
        </a>{" "}
        –{" "}
        <a
          href="https://www.dekoder.org/impressum"
          rel="noopener noreferrer"
          target="_blank"
        >
          Impressum
        </a>
        <div className="ph-footer-iconmenu">
          <ul className="ph-footer-iconmenu-list">
            {iconMenuItems.map((it, i) => (
              <li key={i} className="ph-iconmenu-item">
                <a
                  className="ph-iconmenu-link"
                  title={it.name}
                  href={it.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={it.icon} />
                  <span className="ph-iconmenu-label">{it.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </footer>
      {hasLogoFooter && <div className="ph-footer-logos">
        {!!projectPartners.length && (
          <p className="ph-footer-logos-text">
            {translations.projectPartners[lang.id]}:
          </p>
        )}
        {projectPartners.map((partner) => (
          <a
            key={"footer-coop-partner-" + partner.name[lang.id]}
            className="ph-footer-logo-link"
            href={
              typeof partner.url === "object"
                ? partner.url[lang.id]
                : partner.url
            }
            target="_blank"
            rel="noopener noreferrer"
            data-popup={
              partner.name[lang.id] +
              (partner.text[lang.id] ? "<br/>" + partner.text[lang.id] : "")
            }
          >
            <img
              className="ph-footer-logo"
              alt={partner.name[lang.id]}
              src={partner.logo}
              style={{
                width: `${partner.logoWidth || LOGO_DEFAULT_WIDTH}%`,
                transform: `scale(${partner.scale || 1})`,
              }}
            />
          </a>
        ))}
        {!!mediaPartners.length && (
          <p className="ph-footer-logos-text">
            {translations.mediaPartners[lang.id]}:
          </p>
        )}
        {mediaPartners.map((partner) => (
          <a
            key={"footer-media-partner-" + partner.name[lang.id]}
            className="ph-footer-logo-link"
            href={
              typeof partner.url === "object"
                ? partner.url[lang.id]
                : partner.url
            }
            target="_blank"
            rel="noopener noreferrer"
            data-popup={
              partner.name[lang.id] +
              (partner.text[lang.id] ? "<br/>" + partner.text[lang.id] : "")
            }
          >
            <img
              className="ph-footer-logo"
              alt={partner.name[lang.id]}
              src={partner.logo}
              style={{
                width: `${partner.logoWidth || LOGO_DEFAULT_WIDTH}%`,
                transform: `scale(${partner.scale || 1}`,
              }}
            />
          </a>
        ))}
      </div>}
    </div>
  )
}

export default Footer
