exports.categoryIds = [243, 343]

exports.siteMetaInfo = {
  de: {
    title: "Russlanddeutsches Diarama",
    subtitle: "Erinnerungen, Identitäten und Migrationsgeschichten",
    description: "Erinnerungen, Identitäten und Migrationsgeschichten",
    imageUrl: "https://specials.dekoder.org/content/uploads/2020/07/Sowjetbürger-Aufmacher-Alternative-Pilze-1-scaled-e1601376973133.jpg",
    slug: "/de"
  },
  ru: {
    title: "Российские немцы",
    subtitle: "Истории миграции и поиски идентичности",
    description: "Истории миграции и поиски идентичности",
    imageUrl: "https://specials.dekoder.org/content/uploads/2020/07/Sowjetbürger-Aufmacher-Alternative-Pilze-1-scaled-e1601376973133.jpg",
    slug: "/ru"
  }
}

exports.socialImgComp = require.resolve("./src/components/social-image/social-image.js")

exports.siteMetadata = {
  title: `Russlanddeutsches Diarama`,
  description: `Erinnerungen, Identitäten und Migrationsgeschichten`,
  siteUrl: `https://nemcy.dekoder.org`,
  author: `dekoder.org`
}

exports.pathPrefix = ""
exports.faviconPath = `../shared/src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/layout")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts`

exports.trackingId = "UA-66707787-5"