import { articleRed } from "../shared/src/components/app/globals.module.scss"
import dekoderLogo from "../shared/src/images/logos/dekoder-white.svg"
import dekoderLogoSlogan from "../shared/src/images/logos/dekoder-black-slogan.svg"

import ikgnLogo from "./src/images/logos/ikgn.jpg"
import uniHamburgLogo from "./src/images/logos/uni-hamburg.png"
import bkmLogo from "./src/images/logos/bkm.svg"

import skatert from "./src/images/skatert_new.jpg"

export const backgroundImage = skatert

export const tileSize = {
  width: 320,
  height: 298
}

export const flexHook = ({ x, y, i, itemsPerRow }) => {
  if (itemsPerRow === 4) {
    return (i % 2)
      ? [x, y + 80]
      : [x, y]
  }
}

export const socialImgDefault = {
  de: "https://specials.dekoder.org/content/uploads/2020/10/rd-social-de2.png",
  ru: "https://specials.dekoder.org/content/uploads/2020/10/rd-social-ru2.png"
}

export { siteMetaInfo, siteMetadata, restApi, categoryIds } from "./meta-data-node"


export const siteLogos = [
  { 
    src: dekoderLogo, 
    url: {
      de: "https://specials.dekoder.org/de",
      en: "https://specials.dekoder.org/en",
      ru: "https://specials.dekoder.org/ru",
    },
    scale: 0.8,
    slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]"
    }
  }
]


export const categories = [
  {
    id: 0,
    key: "article",
    color: articleRed,
    selected: true,
    label: {
      de: "Geschichten",
      ru: "Истории"
    }
  },
  {
    id: 1,
    key: "gnose",
    color: articleRed, // gnoseBlue,
    selected: true,
    label: {
      de: "Hintergründe",
      ru: "Контекст"
    }
  },
  {
    id: 2,
    key: "special",
    color: articleRed, // specialContentColor,
    selected: true,
    label: {
      de: "Spezial",
      ru: "Спец"
    }
  },
  {
    id: 3,
    key: "about",
    color: "black",
    selected: true,
    hide: true,
    label: {
      de: "Über das Projekt",
      ru: "О проекте"
    }
  }
]

export const projectPartners = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Расшифровать Россию"
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.dekoder.org/",
      en: "https://specials.dekoder.org/en",
      ru: "https://www.dekoder.org/ru"
    },
    logo: dekoderLogoSlogan,
    scale: 0.75,
  },
  {
    name: {
      de: "Institut für Kultur und Geschichte der Deutschen in Nordosteuropa e.V. (IKGN)",
      ru: "Институт культуры и истории немцев в Северо-Восточной Европе (IKGN e.V.)",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.ikgn.de/",
    logo: ikgnLogo,
    scale: 0.5,
  },
  {
    name: {
      de: "Universität Hamburg",
      ru: "Университет Гамбурга",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.uni-hamburg.de/",
    logo: uniHamburgLogo,
    scale: 0.8,
  },
  {
    name: {
      de: "Die Beauftragte der Bundesregierung für Kultur und Medien",
      ru: "Die Beauftragte der Bundesregierung für Kultur und Medien",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.bundesregierung.de/breg-de/bundesregierung/staatsministerin-fuer-kultur-und-medien",
    logo: bkmLogo,
    scale: 1
  },
]

export const mediaPartners = [
]
